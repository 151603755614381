import * as Sentry from '@sentry/vue';
import SentryFullStory from '@sentry/fullstory';
import { useRouter } from 'vue-router';

const severity = {
    FATAL: 'fatal',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
    DEBUG: 'debug',
};

const isSentryEnabled = Boolean(import.meta.env.SENTRY_DSN)
    && import.meta.env.VITE_THIRD_PARTY_LOGGING === 'enabled';

export default {
    init(app) {
        try {
            Sentry.init({
                app,
                dsn: import.meta.env.SENTRY_DSN,
                environment: process.env.NODE_ENV,
                release: import.meta.env.SENTRY_RELEASE,
                ignoreErrors: [
                    /^Request failed with status code \d{3}$/,
                    /Object Not Found Matching Id:\d+/,
                    'Network error: Failed to fetch',
                    'ChunkLoadError',
                    'Redirected when going from', // https://github.com/vuejs/vue-router/blob/v3.3.4/src/history/errors.js#L13
                    'Avoided redundant navigation', // https://github.com/vuejs/vue-router/blob/v3.3.4/src/history/errors.js#L24
                    'Navigation cancelled', // https://github.com/vuejs/vue-router/blob/v3.3.4/src/history/errors.js#L33
                    'Navigation aborted', // https://github.com/vuejs/vue-router/blob/v3.3.4/src/history/errors.js#L44
                ],
                integrations: [
                    new SentryFullStory(import.meta.env.SENTRY_ORG),
                    new Sentry.BrowserTracing({
                        routingInstrumentation: Sentry.vueRouterInstrumentation(useRouter()),
                    }),
                ],
                denyUrls: [
                    // Ignore FullStory errors
                    /rs\.fullstory\.com/i,
                ],
            });
        } catch (e) { // eslint-disable-line no-unused-vars
            // swallow sentry failures
        }
    },

    setUser({ casId }) {
        try {
            if (casId && isSentryEnabled) {
                Sentry.getCurrentScope().setUser({ id: casId });
            }
        } catch (e) { // eslint-disable-line no-unused-vars
            // swallow sentry failures
        }
    },

    log(message, data = {}, level = severity.WARNING) {
        try {
            if (isSentryEnabled) {
                Sentry.withScope((scope) => {
                    scope.setLevel(level);

                    Object.keys(data).forEach((key) => {
                        scope.setExtra(key, data[key]);
                    });

                    Sentry.captureMessage(message, { level });
                });
            }
        } catch (e) { // eslint-disable-line no-unused-vars
            // swallow sentry failures
        }
    },

    captureException(error, options = {}) {
        try {
            if (isSentryEnabled) {
                Sentry.withScope((scope) => {
                    const { message, data } = options;

                    if (message) {
                        scope.setTransactionName(message);
                    }

                    if (data) {
                        Object.keys(data).forEach((key) => {
                            scope.setExtra(key, data[key]);
                        });
                    }

                    Sentry.captureException(error);
                });
            }
        } catch (e) { // eslint-disable-line no-unused-vars
            // swallow sentry failures
        }
    },

    severity,
};
