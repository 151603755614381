/* eslint-disable */

import { whenAvailable } from '@/shared/utils/window.util';

export const createPendo = ({ apiKey, enabled }) => {
    const attachScripts = () => {
        !(function(e, n, t, i, a) {
            let c; let o; let d; let s; let p;

            for ((a = e[i] = e[i] || {})._q = [], o = 0, d = (c = ['initialize', 'identify', 'updateOptions', 'pageLoad']).length; o < d; ++o)!(function(e) { a[e] = a[e] || function() { a._q[e === c[0] ? 'unshift' : 'push']([e].concat([].slice.call(arguments, 0))); }; }(c[o])); (s = n.createElement(t)).async = !0, s.src = 'https://cdn.pendo.io/agent/static/' + import.meta.env.VITE_PENDO_API_KEY + '/pendo.js', (p = n.getElementsByTagName(t)[0]).parentNode.insertBefore(s, p);
        }(window, document, 'script', 'pendo'));
    };

    const initialize = ({
        casId,
        partner,
    }) => {
        whenAvailable('pendo', (pendo) => {
            pendo.TOOLTIP_ARROW_SIZE = 8;
            pendo.initialize({
                apiKey,

                usePendoAgentAPI: false,

                visitor: {
                    id: casId,
                    isPartner: partner,
                },
                // If we want to use Pendo feedback, an Account ID is required
                //
                // account: {
                //     id: appName,
                // },
            });
        });
    };

    const track = (eventName, eventProperties) => {
        whenAvailable('pendo', (pendo) => {
            pendo.track(eventName, eventProperties);
        });
    };

    return {
        attachScripts: enabled ? attachScripts : () => {},
        initialize: enabled ? initialize : () => {},
        track: enabled ? track : () => {},
    };
};
