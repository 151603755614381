import { inject } from 'vue';
import { createPendo } from './pendo.util';

export const PENDO_KEY = 'pendo';

export function createPendoAnalytics(options) {
    const pendo = createPendo(options);

    return {
        pendo,
        install(app) {
            app.provide(PENDO_KEY, pendo);
        },
    };
}

export function usePendo() {
    return inject(PENDO_KEY);
}
