<script>
import { mapGetters, mapState } from 'vuex';

import sentry from '@/analytics/sentry';
import fullstory from '@/analytics/fullstory';
import { useAuth0 } from '@auth0/auth0-vue';
import { usePendo } from '@/analytics/pendo';

export default {
    setup() {
        const auth = useAuth0();

        return {
            auth,
            pendo: usePendo(),
        };
    },

    data() {
        return {
            user: null,
            isAuthenticated: false,
        };
    },

    mounted() {
        this.user = this.auth.user;
        this.isAuthenticated = this.auth.isAuthenticated;

        this.init();
    },
    computed: {
        ...mapState({
            featureFlags: ({ global }) => global.featureFlags,
        }),

        ...mapGetters({
            isPartner: 'auth/isUserAPartner',
        }),

        analyticsConfigData() {
            const featureFlags = this.featureFlags || {};
            return {
                casId: this.user?.sub,
                partner: this.isPartner,
                featureFlags: this.featureFlags && Object.keys(this.featureFlags).filter((key) => featureFlags[key] === true),
            };
        },

        userDomain() {
            return this.user?.email?.split('@')[1];
        },
    },

    methods: {
        init() {
            if (this.isAuthenticated) {
                sentry.setUser(this.analyticsConfigData);
                fullstory.identify(this.user?.sub, {
                    environment: import.meta.env.VITE_ENV,
                });
                this.pendo.initialize(this.analyticsConfigData);
            }
        },
    },
};
</script>

<template>
    <div />
</template>
