export const whenAvailable = (name, callback) => {
    const interval = 200;
    let attempts = 0;

    const handle = window.setInterval(() => {
        if (attempts > 20) {
            clearInterval(handle);
        } else if (window[name]) {
            clearInterval(handle);
            callback(window[name]);
        }

        attempts++;
    }, interval);
};
